import { useForm } from 'react-hook-form'
import {
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  Stack,
  Heading,
  Select,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import authenticatedFetch from '../auth/auth-utility'
import { serverUrl } from '../util/constants'

export default function CreateEval() {
  const { register, handleSubmit, formState } = useForm()
  const navigate = useNavigate()

  async function onSubmit(values: any) {
    // Make POST request
    let response
    try {
      response = await authenticatedFetch(`${serverUrl}/evals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/')
        } else {
          throw new Error(`error creating an evaluation ${response.statusText}`)
        }
      } else {
        navigate('/evals')
      }
    } catch (error: any) {
      throw error
    }
  }

  return (
    <Box
      maxW="80%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading mb={5}>Create an Evaluation</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5}>
          <FormControl>
            <FormLabel htmlFor="short_name">Short Name</FormLabel>
            <Input
              placeholder="Enter a short name for the evaluation"
              {...register('short_name', { required: true })}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="system_prompt">System Prompt</FormLabel>
            <Input
              placeholder="Enter the system prompt"
              {...register('system_prompt', { required: true })}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="user_prompt">User Prompt</FormLabel>
            <Input
              placeholder="Enter the user prompt"
              {...register('user_prompt', { required: true })}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="model">Model</FormLabel>
            <Select
              placeholder="Select Model"
              {...register('model', { required: true })}
            >
              <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
              <option value="gpt-4">gpt-4</option>
              <option value="gpt-4-32k">gpt-4-32k</option>
            </Select>
          </FormControl>

          <Button
            mt={4}
            isLoading={formState.isSubmitting}
            type="submit"
            disabled={!formState.isValid}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
