import { GoogleLogin } from '@react-oauth/google'
import { useContext } from 'react'
import { AuthContext } from '../auth/auth-context'
import jwt_decode from 'jwt-decode'

import { Box, Flex, Text, Avatar, Button, Heading, VStack } from '@chakra-ui/react'

import { Link } from 'react-router-dom'

interface JWTDecoded {
  email: string
  name: string
  picture: string
}

const Home = () => {
  const { isAuthenticated, login, logout, token } = useContext(AuthContext)

  const renderUserProfile = () => {
    const decoded: JWTDecoded = jwt_decode(token!)
    const { email, name, picture } = decoded

    return (
      <Box
        maxW="90%"
        p={5}
        mt={10}
        mx="auto"
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
      >
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Avatar size="2xl" src={picture} mb={4} />
          <Heading mb={2}>{name}</Heading>
          <Text>{email}</Text>
          <VStack spacing={3} align="stretch" mt={4}>
            <Link to="/problems/create">
              <Button colorScheme="teal">Add a Problem</Button>
            </Link>
            <Link to="/problems">
              <Button colorScheme="teal">View all Problems</Button>
            </Link>
            <Link to="/evals/create">
              <Button colorScheme="teal">Create an Eval</Button>
            </Link>
            <Link to="/evals">
              <Button colorScheme="teal">View all Evals</Button>
            </Link>
            <Button colorScheme="teal" onClick={logout}>
              Logout
            </Button>
          </VStack>
        </Flex>
      </Box>
    )
  }

  const renderGoogleSignIn = () => (
    <Box
      maxW="90%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Flex flexDirection="column" alignItems="center" textAlign="center">
        <Heading mb={4}>Welcome to MS Question Bank!</Heading>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const token = credentialResponse.credential
            console.log(`Login Success! Token: ${token}`)
            if (token) {
              login(token)
            }
          }}
          onError={() => {
            console.log('Login Failed')
          }}
        />
      </Flex>
    </Box>
  )

  return isAuthenticated ? renderUserProfile() : renderGoogleSignIn()
}

export default Home
