async function authenticatedFetch(url: string, options: RequestInit = {}) {
  const token = localStorage.getItem('token')

  // Set up default headers
  if (!options.headers) {
    options.headers = {}
  }

  // If there's a token, add it to the request
  if (token) {
    const headers =
      options.headers instanceof Headers
        ? options.headers
        : new Headers(options.headers)
    headers.set('Authorization', `Bearer ${token}`)
    options.headers = headers
  }

  // Now we can proceed with fetch using the modified options
  return await fetch(url, options)
}

export default authenticatedFetch
