import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Text,
  Divider,
  Spinner,
  Grid,
  useToast,
} from '@chakra-ui/react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { useParams, useNavigate, Link } from 'react-router-dom'
import authenticatedFetch from '../auth/auth-utility'

// Utils and constants
import { serverUrl } from '../util/constants'
import { Evaluation, ProblemEval } from '../util/interfaces'

// Components
import { ProblemEvalDetail } from '../components/ProblemEvalDetail'


export default function EvalDetail() {
  const [problemEvals, setProblemEvals] = useState<ProblemEval[]>([])
  const [evaluation, setEvaluation] = useState<Evaluation | null>(null)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(1) // Only fetch one ProblemEval at a time
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const toast = useToast()
  const { uuid } = useParams()
  const navigate = useNavigate()

  const fetchData = async () => {
    setIsLoading(true)
    setError(null)

    const fetchUrl = `${serverUrl}/problem-evals?evalId=${uuid}&limit=${limit}&offset=${offset}`

    try {
      const response = await authenticatedFetch(fetchUrl)
      if (response.ok) {
        const data = await response.json()
        console.log('fetched data: ', data)
        setProblemEvals(data)
      } else if (response.status === 401) {
        navigate('/')
      } else {
        throw new Error('Error fetching data')
      }
    } catch (error: any) {
      setError(error.message)
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchEvalDetails = async () => {
      const response = await authenticatedFetch(`${serverUrl}/evals/${uuid}`)
      if (response.ok) {
        const data = await response.json()
        setEvaluation(data)
      } else {
        // handle error
        console.log('Error fetching evaluation details')
        console.log(response)
      }
    }
    fetchEvalDetails()
  }, [uuid])

  useEffect(() => {
    fetchData()
  }, [uuid, offset])

  const navigateProblemEvals = (increment: number) => {
    setOffset((prevOffset) => prevOffset + increment * limit)
  }

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" minH="80vh">
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" minH="80vh">
        <Text fontSize="2xl" color="red.500">
          Error: {error}
        </Text>
      </Flex>
    )
  }

  const problemEval = problemEvals[0] // Only first problem eval is displayed

  return (
    <Box
      maxW="90%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Link to="/evals">
        <Button colorScheme="teal" mb={4}>
          View all evaluations
        </Button>
      </Link>

      {evaluation ? (
        <>
          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
            gap={6}
            borderWidth={1}
            borderRadius="lg"
            p={5}
            mb={5}
          >
            <Box mb={5}>
              <Text fontSize="lg" fontWeight="bold">Evaluation Name:</Text> 
              <Text>{evaluation.short_name}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">Model:</Text> 
              <Text>{evaluation.model}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">System Prompt:</Text> 
              <Text>{evaluation.system_prompt}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">User Prompt:</Text> 
              <Text>{evaluation.user_prompt}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">Number of Problems:</Text> 
              <Text>{evaluation.num_problems}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">Accuracy:</Text> 
              <Text>{evaluation.accuracy}</Text>
            </Box>
          </Grid>

          {problemEval && (
            <>
              <Divider mt={5} mb={5} />
              <ProblemEvalDetail problemEval={problemEval} />

              <Flex justifyContent="space-between" mt={10}>
                <Button
                  leftIcon={<BiChevronLeft />}
                  isDisabled={offset === 0}
                  onClick={() => navigateProblemEvals(-1)}
                >
                  Previous
                </Button>

                <Button
                  rightIcon={<BiChevronRight />}
                  isDisabled={offset + limit >= evaluation.num_problems}
                  onClick={() => navigateProblemEvals(1)}
                >
                  Next
                </Button>
              </Flex>
            </>
          )}
        </>
      ) : (
        <Text fontSize="lg">No evaluation details available</Text>
      )}
    </Box>
  )
}
