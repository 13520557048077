import { useForm } from 'react-hook-form'
import {
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Box,
  Stack,
  Heading,
  useDisclosure,
  Collapse,
  Checkbox,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MarkdownEditor from '../components/MarkdownEditor'
import LaTeXCheatsheet from '../components/LatexCheatsheet'

import authenticatedFetch from '../auth/auth-utility'

// Constants
import { difficulties, topics, gradeLevels, serverUrl } from '../util/constants'

// Util Functions
import { formatStringEnums } from '../util/functions'

const mdKaTeX = `This is to display the 
\`\$\$\c = \\pm\\sqrt{a^2 + b^2}\$\$\`
 in one line

\`\`\`KaTeX
c = \\pm\\sqrt{a^2 + b^2}
\`\`\`
`

export default function CreateProblem() {
  const { register, handleSubmit, formState} = useForm()

  // for Latex cheatsheet
  const { isOpen, onToggle } = useDisclosure()

  // states for each MarkDownEditor
  const [questionDescription, setQuestionDescription] = useState(mdKaTeX)
  const [workings, setWorkings] = useState(mdKaTeX)
  const [finalAnswer, setFinalAnswer] = useState(mdKaTeX)

  const navigate = useNavigate()

  async function onSubmit(values: any) {
    // manually set Markdown Editor Values
    values['question_description'] = questionDescription
    values['workings'] = workings
    values['final_answer'] = finalAnswer

    // Make POST request
    let response
    try {
      response = await authenticatedFetch(`${serverUrl}/problems`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/')
        } else {
          throw new Error(`error creating a problem ${response.statusText}`)
        }
      } else {
        navigate('/problems')
      }
    } catch (error: any) {
      throw error
    }
  }

  return (
    <Box
      maxW="80%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading mb={5}>Add a Problem</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5}>
          <FormControl>
            <FormLabel htmlFor="name">Question Name</FormLabel>
            <Input
              placeholder="Enter a short name for the question"
              {...register('question_name', { required: true })}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Difficulty</FormLabel>
            <Select
              placeholder="Select Difficulty"
              {...register('difficulty', { required: true })}
            >
              {difficulties.map((difficulty) => {
                return (
                  <option key={difficulty} value={difficulty}>
                    {formatStringEnums(difficulty)}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Topic</FormLabel>
            <Select
              placeholder="Select Topic"
              {...register('topic', { required: true })}
            >
              {topics.map((topic) => {
                return (
                  <option key={topic} value={topic}>
                    {formatStringEnums(topic)}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormControl>Grade Level</FormControl>
            <Select
              placeholder="Select Grade Level"
              {...register('grade_level', { required: true })}
            >
              {gradeLevels.map((gradeLevel) => {
                return (
                  <option key={gradeLevel} value={gradeLevel}>
                    {formatStringEnums(gradeLevel)}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="is_multi_part">Is Multi Part?</FormLabel>
            <Checkbox {...register('is_multi_part')}>Yes</Checkbox>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="is_incomplete">Is Incomplete?</FormLabel>
            <Checkbox {...register('is_incomplete')}>Yes</Checkbox>
          </FormControl>
          <Button onClick={onToggle} mt={4}>
            {isOpen ? 'Hide LaTeX Cheatsheet' : 'Show LaTeX Cheatsheet'}
          </Button>
          <Collapse in={isOpen}>
            <LaTeXCheatsheet />
          </Collapse>
          <FormControl>
            <FormLabel>Question Description</FormLabel>
            <MarkdownEditor
              value={questionDescription}
              setValue={setQuestionDescription}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Workings</FormLabel>
            <MarkdownEditor value={workings} setValue={setWorkings} />
          </FormControl>
          <FormControl>
            <FormLabel>Final Answer</FormLabel>
            <MarkdownEditor value={finalAnswer} setValue={setFinalAnswer} />
          </FormControl>
          <Button
            mt={4}
            isLoading={formState.isSubmitting}
            type="submit"
            disabled={!formState.isValid}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
