import { Box, Text, Grid, GridItem, Divider, Tag } from '@chakra-ui/react'
import { MarkdownRenderer } from '../components/MarkdownRenderer'
import { formatStringEnums } from '../util/functions'
import { ProblemEval } from '../util/interfaces'

interface Props {
  problemEval: ProblemEval
}

export function ProblemEvalDetail({ problemEval }: Props) {
  return (
    <Box borderWidth={1} borderRadius="lg" p={5}>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
        <Box mb={5}>
          <Text fontSize="lg" fontWeight="bold">
            Problem Name:
          </Text>
          <Text>{problemEval.problem.question_name}</Text>
        </Box>

        <Box mb={5}>
          <Text fontSize="lg" fontWeight="bold">
            Problem Description:
          </Text>
          <MarkdownRenderer
            content={problemEval.problem.question_description}
          />
        </Box>
      </Grid>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={6}>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Difficulty:
          </Text>
          <Tag>{formatStringEnums(problemEval.problem.difficulty)}</Tag>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Topic:
          </Text>
          <Tag>{formatStringEnums(problemEval.problem.topic)}</Tag>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Grade Level:
          </Text>
          <Tag>{formatStringEnums(problemEval.problem.grade_level)}</Tag>
        </Box>
      </Grid>

      <Box mt={5}>
        <Text fontSize="lg" fontWeight="bold">
          Correctly Solved:
        </Text>
        <Tag colorScheme={problemEval.correctly_solved ? 'green' : 'red'}>
          {problemEval.correctly_solved ? 'Yes' : 'No'}
        </Tag>
      </Box>

      <Divider my={4} />
      
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            LLM Working:
          </Text>
          <MarkdownRenderer content={problemEval.llm_working} />
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Actual Working:
          </Text>
          <MarkdownRenderer content={problemEval.problem.workings} />
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            LLM Answer:
          </Text>
          <MarkdownRenderer content={problemEval.llm_answer} />
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Actual Answer:
          </Text>
          <MarkdownRenderer content={problemEval.problem.final_answer} />
        </GridItem>
      </Grid>
    </Box>
  )
}
