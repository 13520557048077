import MDEditor, { commands } from '@uiw/react-md-editor'
import { getCodeString } from 'rehype-rewrite'
import katex from 'katex'
import 'katex/dist/katex.css'

interface MarkdownEditorProps {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
}

const help = {
  name: 'help',
  keyCommand: 'help',
  buttonProps: { 'aria-label': 'Insert help' },
  icon: (
    <svg viewBox="0 0 16 16" width="12px" height="12px">
      <path
        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm.9 13H7v-1.8h1.9V13Zm-.1-3.6v.5H7.1v-.6c.2-2.1 2-1.9 1.9-3.2.1-.7-.3-1.1-1-1.1-.8 0-1.2.7-1.2 1.6H5c0-1.7 1.2-3 2.9-3 2.3 0 3 1.4 3 2.3.1 2.3-1.9 2-2.1 3.5Z"
        fill="currentColor"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    window.open('https://www.markdownguide.org/basic-syntax/', '_blank')
  },
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({ value, setValue }) => {
  return (
    <MDEditor
      value={value}
      onChange={(val) => {
        if (val) {
          setValue(val)
        } else {
          setValue('')
        }
      }}
      previewOptions={{
        components: {
          code: ({ inline, children = [], className, ...props }) => {
            const txt = children[0] || ''
            if (inline) {
              if (typeof txt === 'string' && /^\$\$(.*)\$\$/.test(txt)) {
                const content = txt.replace(/^\$\$(.*)\$\$/, '$1')
                const isDisplayMode = /\\tag{.*?}/.test(content)
                const html = katex.renderToString(content, {
                  throwOnError: false,
                  displayMode: isDisplayMode,
                })
                return <code dangerouslySetInnerHTML={{ __html: html }} />
              }
              return <code>{txt}</code>
            }
            const code =
              props.node && props.node.children
                ? getCodeString(props.node.children)
                : txt
            if (
              typeof code === 'string' &&
              typeof className === 'string' &&
              /^language-katex/.test(className.toLocaleLowerCase())
            ) {
              const isDisplayMode = /\\tag{.*?}/.test(code)
              const html = katex.renderToString(code, {
                throwOnError: false,
                displayMode: isDisplayMode,
              })
              return (
                <code
                  style={{ fontSize: '150%' }}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              )
            }
            return <code className={String(className)}>{txt}</code>
          },
        },
      }}
      commands={[...commands.getCommands(), help]}
    />
  )
}

export default MarkdownEditor
