import { Box, Button, Link, Text, useColorModeValue } from '@chakra-ui/react'

export default function Footer() {
  const backgroundColor = useColorModeValue('gray.50', 'gray.800')
  const color = useColorModeValue('gray.800', 'gray.50')

  return (
    <Box
      bg={backgroundColor}
      color={color}
      position="fixed"  
      left="0"
      bottom="0"
      width="100%"
      py="2"
      zIndex="100"
    >
      <Box textAlign="center">
        <Text>Have a problem, suggestion or feedback?</Text>
        <Link href="https://forms.gle/uzxMWP4MdQS1R6Si9" isExternal>
          <Button colorScheme="teal" mt="2">
            Give Feedback
          </Button>
        </Link>
      </Box>
    </Box>
  )
}
