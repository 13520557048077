import { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Flex,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import authenticatedFetch from '../auth/auth-utility'

interface Eval {
  uuid: string
  short_name: string
  num_problems: number
  accuracy: number
  date_created: string
  model: string
}

export default function EvalsList() {
  const [evals, setEvals] = useState<Eval[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvals = async () => {
      setIsLoading(true)
      setError(null)

      const serverUrl =
        process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'

      try {
        const response = await authenticatedFetch(`${serverUrl}/evals`)
        if (response.ok) {
          const data = await response.json()
          setEvals(data)
        } else if (response.status === 401) {
          navigate('/');
        } else {
          throw new Error('Error fetching data')
        }
      } catch (error: any) {
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchEvals()
  }, [navigate])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <Box
      maxW="90%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={5}>
        <Heading>All Evals</Heading>
        <Link to="/evals/create">
          <Button colorScheme="teal">Create an Eval</Button>
        </Link>
      </Flex>
      <Box overflowX={{ base: 'scroll', md: 'auto' }}>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Short Name</Th>
              <Th>Num Problems</Th>
              <Th>Accuracy</Th>
              <Th>Date Created</Th>
              <Th>Model</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {evals.map((currEval) => (
              <Tr key={currEval.uuid}>
                <Td>{currEval.short_name}</Td>
                <Td>{currEval.num_problems}</Td>
                <Td>{currEval.accuracy}</Td>
                <Td>{new Date(currEval.date_created).toLocaleDateString()}</Td>
                <Td>{currEval.model}</Td>
                <Td>
                  <Link to={`/evals/${currEval.uuid}`}>
                    <Button colorScheme="teal" size="sm">
                      View
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}
