import { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Flex,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import authenticatedFetch from '../auth/auth-utility'

// Constants
import {
  difficulties,
  topics,
  gradeLevels,
  serverUrl,
  added_bys,
} from '../util/constants'

// Utils
import { formatStringEnums } from '../util/functions'

interface Problem {
  uuid: string
  question_name: string
  difficulty: string
  topic: string
  grade_level: string
  approved: boolean
  in_complete: boolean
}

export default function ProblemsList() {
  const [problems, setProblems] = useState<Problem[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const limitPerPage = 10

  const [filters, setFilters] = useState({
    difficulty: '',
    topic: '',
    grade_level: '',
    added_by: '',
    in_complete: '',
  })

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  useEffect(() => {
    const fetchProblems = async () => {
      setIsLoading(true)
      setError(null)

      try {
        const response = await authenticatedFetch(
          `${serverUrl}/problems?limit=${limitPerPage}&offset=${
            page * limitPerPage
          }&difficulty=${filters.difficulty}&grade_level=${
            filters.grade_level
          }&topic=${filters.topic}&added_by=${filters.added_by}&is_incomplete=${
            filters.in_complete
          }`,
        )
        if (response.ok) {
          const { problems, totalCount } = await response.json()
          console.log(`count value seen is ${totalCount}`)
          setProblems(problems)
          setTotalCount(totalCount)
        } else if (response.status === 401) {
          navigate('/')
        } else {
          throw new Error('Error fetching data')
        }
      } catch (error: any) {
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProblems()
  }, [page, totalCount, navigate, filters])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <Box
      maxW="90%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={5}>
        <Heading>All Problems</Heading>
        <Link to="/problems/create">
          <Button colorScheme="teal">Add a Problem</Button>
        </Link>
      </Flex>
      <Heading size="md" mb={3}>
        Filter Results
      </Heading>{' '}
      {/* Subheading */}
      <Stack direction="row" spacing={5} justifyContent="space-between" mb={5}>
        <FormControl>
          <FormLabel>Difficulty</FormLabel>
          <Select
            name="difficulty"
            value={filters.difficulty}
            onChange={handleFilterChange}
          >
            <option value=""> Any </option> {/* Default option */}
            {difficulties.map((difficulty) => {
              return (
                <option key={difficulty} value={difficulty}>
                  {formatStringEnums(difficulty)}
                </option>
              )
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Grade Level</FormLabel>
          <Select
            name="grade_level"
            value={filters.grade_level}
            onChange={handleFilterChange}
          >
            <option value=""> Any </option> {/* Default option */}
            {gradeLevels.map((gradeLevel) => {
              return (
                <option key={gradeLevel} value={gradeLevel}>
                  {formatStringEnums(gradeLevel)}
                </option>
              )
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Topic</FormLabel>
          <Select
            name="topic"
            value={filters.topic}
            onChange={handleFilterChange}
          >
            <option value=""> Any </option> {/* Default option */}
            {topics.map((topic) => {
              return (
                <option key={topic} value={topic}>
                  {formatStringEnums(topic)}
                </option>
              )
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Added By</FormLabel>
          <Select
            name="added_by"
            value={filters.added_by}
            onChange={handleFilterChange}
          >
            <option value=""> Any </option> {/* Default option */}
            {added_bys.map((added_by) => {
              return (
                <option key={added_by} value={added_by}>
                  {formatStringEnums(added_by)}
                </option>
              )
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Incomplete</FormLabel>
          <Select
            name="in_complete"
            value={filters.in_complete}
            onChange={handleFilterChange}
          >
            <option value="">Any</option> {/* Default option */}
            <option value="true">True</option>
            <option value="false">False</option>
          </Select>
        </FormControl>
        {filters.added_by && (
          <Text mt={2} color="teal.600">
            Total problems added by {formatStringEnums(filters.added_by)}:{' '}
            {totalCount}
          </Text>
        )}
      </Stack>
      <Box overflowX={{ base: 'scroll', md: 'auto' }}>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Question Name</Th>
              <Th>Difficulty</Th>
              <Th>Topic</Th>
              <Th>Grade Level</Th>
              <Th>Approved</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {problems.map((problem) => (
              <Tr key={problem.uuid}>
                <Td>{problem.question_name}</Td>
                <Td>{problem.difficulty}</Td>
                <Td>{problem.topic}</Td>
                <Td>{problem.grade_level}</Td>
                <Td>{problem.approved ? 'Yes' : 'No'}</Td>
                <Td>
                  <Link to={`/problems/${problem.uuid}`}>
                    <Button colorScheme="teal" size="sm">
                      View
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justifyContent="center" mt={5}>
        <Button
          onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          disabled={page === 0}
        >
          Previous
        </Button>
        <Button
          onClick={() => setPage((prev) => prev + 1)}
          disabled={(page + 1) * limitPerPage >= totalCount}
          ml={2}
        >
          Next
        </Button>
      </Flex>
    </Box>
  )
}
