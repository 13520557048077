import jwt_decode from 'jwt-decode'

interface DecodedToken {
  exp: number
}

export const isTokenExpired = (token: string) => {
  try {
    const decoded: DecodedToken = jwt_decode(token)
    return decoded.exp * 1000 < new Date().getTime()
  } catch (e) {
    console.error('Failed to decode or check token', e)
    return true // If there's an error, assume the token is expired
  }
}
