import { useEffect, useState, useRef } from 'react'
import {
  Box,
  Heading,
  List,
  ListItem,
  Text,
  Grid,
  GridItem,
  Button as ChakraButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Badge,
} from '@chakra-ui/react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import authenticatedFetch from '../auth/auth-utility'

// Components
import { MarkdownRenderer } from '../components/MarkdownRenderer'

interface Problem {
  uuid: string
  question_name: string
  question_description: string
  workings: string
  final_answer: string
  difficulty: string
  topic: string
  grade_level: string
  added_by: string
  date_created: string
  date_modified: string
  approved: boolean
  is_multi_part: boolean
  is_incomplete: boolean
}

const ProblemDetail = () => {
  const [problem, setProblem] = useState<Problem | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  let { uuid } = useParams<{ uuid: string }>()

  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef(null)

  const navigate = useNavigate()

  const onDelete = async () => {
    const serverUrl =
      process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'

    try {
      const response = await authenticatedFetch(
        `${serverUrl}/problems/${uuid}`,
        {
          method: 'DELETE',
        },
      )

      if (response.ok) {
        // handle successful deletion here
        onClose() // Close the dialog box

        // Redirect the user to problems page
        navigate('/problems')
      } else if (response.status === 401) {
        navigate('/')
      } else {
        throw new Error('Error deleting problem')
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchProblem = async () => {
      setIsLoading(true)
      setError(null)

      const serverUrl =
        process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'

      try {
        const response = await authenticatedFetch(
          `${serverUrl}/problems/${uuid}`,
        )

        if (response.ok) {
          const data = await response.json()
          setProblem(data)
        } else {
          throw new Error('Error fetching data')
        }
      } catch (error: any) {
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProblem()
  }, [uuid])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (!problem) {
    return <div>Problem not found</div>
  }

  return (
    <>
      <Box
        maxW="90%"
        p={5}
        mt={10}
        mx="auto"
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
      >
        <Link to="/problems">
          <ChakraButton colorScheme="teal" mb={4}>
            View all problems
          </ChakraButton>
        </Link>
        <Heading mb={5}>{problem.question_name}</Heading>

        {/* Grid for book-keeping details */}
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={6}
          mb={6}
        >
          <GridItem>
            <Text fontWeight="bold">Difficulty:</Text>
            <Text>{problem.difficulty}</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Topic:</Text>
            <Text>{problem.topic}</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Grade Level:</Text>
            <Text>{problem.grade_level}</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Added By:</Text>
            <Text>{problem.added_by}</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Approved:</Text>
            <Badge colorScheme={problem.approved ? 'green' : 'red'}>
              {problem.approved ? 'Approved' : 'Not Approved'}
            </Badge>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Is Multi-Part:</Text>
            <Text>{problem.is_multi_part ? 'Yes' : 'No'}</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Is Incomplete:</Text>
            <Text>{problem.is_incomplete ? 'Yes' : 'No'}</Text>
          </GridItem>
        </Grid>
        <List spacing={3}>
          <ListItem>
            <Text fontWeight="bold">Question Description:</Text>
            <MarkdownRenderer content={problem.question_description} />
          </ListItem>
          <ListItem>
            <Text fontWeight="bold">Workings:</Text>
            <div style={{ margin: 0, padding: 0 }}>
              <MarkdownRenderer content={problem.workings} />
            </div>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold">Final Answer:</Text>
            <MarkdownRenderer content={problem.final_answer} />
          </ListItem>
        </List>
        <Grid templateColumns="repeat(2, 1fr)" gap={3} mb={3} mt={5}>
          <GridItem>
            <Link to={`/problems/${uuid}/edit`}>
              <ChakraButton colorScheme="blue" mb={4}>
                Edit
              </ChakraButton>
            </Link>
          </GridItem>
          <GridItem>
            <ChakraButton
              colorScheme="red"
              mb={4}
              onClick={() => setIsOpen(true)}
            >
              Delete
            </ChakraButton>
          </GridItem>
        </Grid>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Problem
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <ChakraButton ref={cancelRef} onClick={onClose}>
                Cancel
              </ChakraButton>
              <ChakraButton colorScheme="red" onClick={onDelete} ml={3}>
                Delete
              </ChakraButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default ProblemDetail
