import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import { Box } from '@chakra-ui/react'

export const MarkdownRenderer = ({ content }: { content: string }) => {
  return (
    <Box
      as="div"
      className="MarkdownRenderer"
      sx={{
        ol: {
          paddingLeft: 0,
          marginLeft: 0,
        },
      }}
    >
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        components={{
          code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '')
            if (inline) {
              let matchInline
              if (typeof children[0] === 'string') {
                matchInline = children[0].match(/^\$\$(.*)\$\$/)
                if (matchInline) {
                  const currentMathContent = matchInline[1];
                  const isDisplayMode = /\\tag{.*?}/.test(currentMathContent); // Change here
                  const html = katex.renderToString(currentMathContent, {
                    throwOnError: false,
                    displayMode: isDisplayMode,
                  })
                  return <code dangerouslySetInnerHTML={{ __html: html }} />
                }
              }
              return <code>{children}</code>
            }
            if (match && match[1] === 'KaTeX') {
              let text
              if (typeof children[0] === 'string') {
                text = children[0]
                const isDisplayMode = /\\tag{.*?}/.test(text)
                const html = katex.renderToString(text, {
                  throwOnError: false,
                  displayMode: isDisplayMode,
                })
                return <code dangerouslySetInnerHTML={{ __html: html }} />
              }
            }
            return <code className={String(className)}>{children}</code>
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </Box>
  )
}
