import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Button as ChakraButton,
  Grid,
} from '@chakra-ui/react';
import { useParams, Link } from 'react-router-dom';
import authenticatedFetch from '../auth/auth-utility';
import { ProblemEval, Evaluation } from '../util/interfaces';

const EvalOverview = () => {
  const [evalDetails, setEvalDetails] = useState<Evaluation | null>(null);
  const [problemEvals, setProblemEvals] = useState<ProblemEval[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    difficulty: '',
    gradeLevel: '',
    topic: '',
  });

  let { uuid } = useParams();
  console.log(`eval id is ${uuid}`)

  useEffect(() => {
    const fetchEvalDetails = async () => {
      setIsLoading(true);
      setError(null);

      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';

      try {
        const evalResponse = await authenticatedFetch(`${serverUrl}/evals/${uuid}`);
        console.log(`eval response is ${JSON.stringify(evalResponse)}`)
        if (evalResponse.ok) {
          const data = await evalResponse.json();
          setEvalDetails(data);

          const problemEvalsResponse = await authenticatedFetch(
            `${serverUrl}/problem-evals?evalId=${uuid}`
          );
          console.log(`problem eval response is ${problemEvalsResponse}`)
          if (problemEvalsResponse.ok) {
            const problemEvalsData = await problemEvalsResponse.json();
            setProblemEvals(problemEvalsData);
          } else {
            throw new Error('Error fetching problem evals');
          }
        } else {
          throw new Error('Error fetching eval details');
        }
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvalDetails();
  }, [uuid]);

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const applyFilters = async () => {
    setIsLoading(true);
    const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';
    try {
      const response = await authenticatedFetch(
        `${serverUrl}/problem-evals?evalId=${uuid}&difficulty=${filters.difficulty}&gradeLevel=${filters.gradeLevel}&topic=${filters.topic}`
      );
      if (response.ok) {
        const data = await response.json();
        setProblemEvals(data);
      } else {
        throw new Error('Error fetching filtered problem evals');
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box
      maxW="90%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Link to="/evals">
        <Button colorScheme="teal" mb={4}>
          View all evaluations
        </Button>
      </Link>


      {/* Eval details */}

      {evalDetails && (
        <>
          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
            gap={6}
            borderWidth={1}
            borderRadius="lg"
            p={5}
            mb={5}
          >
            <Box mb={5}>
              <Text fontSize="lg" fontWeight="bold">Evaluation Name:</Text> 
              <Text>{evalDetails.short_name}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">Model:</Text> 
              <Text>{evalDetails.model}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">System Prompt:</Text> 
              <Text>{evalDetails.system_prompt}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">User Prompt:</Text> 
              <Text>{evalDetails.user_prompt}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">Number of Problems:</Text> 
              <Text>{evalDetails.num_problems}</Text>
            </Box>
            <Box mb={4}>
              <Text fontSize="lg" fontWeight="bold">Accuracy:</Text> 
              <Text>{evalDetails.accuracy}</Text>
            </Box>
          </Grid>
        </>)
    }
      {/* Filters */}
      <Box mt={5}>
        <Select name="difficulty" onChange={handleFilterChange} placeholder="Select difficulty">
          {/* Add options for difficulty */}
        </Select>
        <Select name="gradeLevel" onChange={handleFilterChange} placeholder="Select grade level">
          {/* Add options for grade level */}
        </Select>
        <Select name="topic" onChange={handleFilterChange} placeholder="Select topic">
          {/* Add options for topic */}
        </Select>
        <ChakraButton onClick={applyFilters}>Apply Filters</ChakraButton>
      </Box>

      {/* Table of ProblemEvals */}
      <Box overflowX={{ base: 'scroll', md: 'auto' }}>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Problem Name</Th>
              <Th>Correctly Solved</Th>
              <Th>Correct Working</Th>
              <Th>Errors</Th>
              <Th>Actions</Th> {/* New column for actions */}
            </Tr>
          </Thead>
          <Tbody>
            {problemEvals.map((problemEval) => (
              <Tr key={problemEval.uuid}>
                <Td>{problemEval.problem.question_name}</Td>
                <Td>{problemEval.correctly_solved ? 'Yes' : 'No'}</Td>
                <Td>{problemEval.correct_working ? 'Yes' : 'No'}</Td>
                <Td>{problemEval.errors.join(', ')}</Td>
                <Td>
                  <Link to={`/problem-eval/${problemEval.uuid}`}>
                    <Button colorScheme="teal" size="sm">
                      View
                    </Button>
                  </Link>
                </Td> {/* New "View" button */}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default EvalOverview;
