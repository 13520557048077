import { ChakraProvider, theme, Box } from '@chakra-ui/react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { AuthContext, AuthProvider } from './auth/auth-context'
import React from 'react'

// Pages
import CreateProblem from './pages/CreateProblem'
import ProblemsList from './pages/ProblemsList'
import ProblemDetail from './pages/ProblemDetail'
import EditProblem from './pages/EditProblem'
import EvalsList from './pages/EvalsList'
import EvalDetail from './pages/EvalDetail'
import CreateEval from './pages/CreateEval'
import Home from './pages/Home'
import ProblemEvalDetail from './pages/ProblemEvalDetail'

// Components
import Footer from './components/Footer'

// Private Route for Auth purposes
import { useContext } from 'react'
import EvalOverview from './pages/EvalOverview'

export const App = () => {
  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId="965266452764-vm9i3qclg03jsalgl0o1hhiub658vbpt.apps.googleusercontent.com">
        <Router>
          <ChakraProvider theme={theme}>
            <Box mb="90px">
              <Routes>
                <Route
                  path="/problems/create"
                  element={<ProtectedRoute component={CreateProblem} />}
                />
                <Route
                  path="/problems/"
                  element={<ProtectedRoute component={ProblemsList} />}
                />
                <Route
                  path="/problems/:uuid"
                  element={<ProtectedRoute component={ProblemDetail} />}
                />
                <Route
                  path="/problems/:uuid/edit"
                  element={<ProtectedRoute component={EditProblem} />}
                />
                <Route
                  path="/evals"
                  element={<ProtectedRoute component={EvalsList} />}
                />
                <Route
                  path='/evals/:uuid'
                  element={<ProtectedRoute component={EvalDetail} />}
                >
                </Route>
                <Route
                  path='/new-eval/:uuid'
                  element={<ProtectedRoute component={EvalOverview } />}
                >
                </Route>
                <Route
                  path='/problem-eval/:uuid'
                  element={<ProtectedRoute component={ProblemEvalDetail} />}
                >
                </Route>
                <Route
                  path='/evals/create'
                  element={<ProtectedRoute component={CreateEval} />}
                >
                </Route>
                <Route path="/" element={<Home />} />
              </Routes>
            </Box>
            <Footer />
          </ChakraProvider>
        </Router>
      </GoogleOAuthProvider>
    </AuthProvider>
  )
}

interface ProtectedRouteProps {
  component: React.ElementType
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { user } = useContext(AuthContext)
  const isAuthenticated = Boolean(user)

  return isAuthenticated ? <Component /> : <Navigate to="/" />
}
