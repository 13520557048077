import { createContext, useState, ReactNode} from 'react';
import { isTokenExpired } from './token-util';
import { Navigate } from 'react-router-dom';

interface User {
    name: string;
    id: string;
}

interface AuthProviderProps {
    children: ReactNode
}

interface AuthContextType {
    isAuthenticated: boolean;
    user: User | null;
    login: (token: string) => void;
    logout: () => void;
    token: string | null;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  login: () => {},
  logout: () => {},
  token: null
});

 // Define the server URL
 const serverUrl =
 process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'


export const AuthProvider = ({ children}: AuthProviderProps) => {
// Try to read the user from localStorage
  const storedUser = localStorage.getItem('user');
  const storedToken = localStorage.getItem('token');
  const [user, setUser] = useState<User | null>(storedUser ? JSON.parse(storedUser) : null);
  const [token, setToken] = useState<string | null>(storedToken);

  const isAuthenticated = !!user && !!token && !isTokenExpired(token);

  const login = async (token: string) => {

    if (isTokenExpired(token)) {
      logout()
      return
    }

    const response = await fetch(`${serverUrl}/auth/validate`, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });

    if (response.ok) {
        const user: User = await response.json()
        setUser(user)
        setToken(token)

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);

        console.log(`successfully set token as ${token}`)
    } else {
        console.error('Failed to login')
    }

  };

  const logout = () => {
    setUser(null);

    // Clear the user and token from localStorage
    localStorage.removeItem('user')
    localStorage.removeItem('token')

    // Redirect to login page
    return <Navigate to="/login" replace={true} />;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
