import { useEffect, useState } from 'react';
import { Box, Text, Button, Grid, GridItem, Divider, Tag, Input, Switch, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { MarkdownRenderer } from '../components/MarkdownRenderer';
import { formatStringEnums } from '../util/functions';
import { ProblemEval } from '../util/interfaces';
import authenticatedFetch from '../auth/auth-utility';
import { serverUrl } from '../util/constants'

export default function ProblemEvalDetailPage() {
  const { uuid } = useParams();
  const [problemEval, setProblemEval] = useState<ProblemEval | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const fetchUrl = `${serverUrl}/problem-evals/${uuid}`; // Fetching one record based on problemEval id

      try {
        const response = await authenticatedFetch(fetchUrl);
        if (response.ok) {
          const data = await response.json();
          setProblemEval(data); 
        } else {
          throw new Error('Error fetching data');
        }
      } catch (error: any) {
        setError(error.message);
        toast({
          title: 'An error occurred.',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [uuid, toast]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveChanges = async () => {
    if (!problemEval) return;
  
    const updateUrl = `${serverUrl}/problem-evals/${uuid}`; // URL to update the problemEval
  
    const updatedData = {
      correctly_solved: problemEval.correctly_solved,
      correct_working: problemEval.correct_working,
      observation: problemEval.observation,
    };
  
    try {
      const response = await authenticatedFetch(updateUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
  
      if (response.ok) {
        const updatedProblemEval = await response.json();
        setProblemEval(updatedProblemEval);
        toast({
          title: 'Success',
          description: 'Data updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Error updating data');
      }
    } catch (error: any) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsEditing(false);
    }
  };
  

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <Text>Loading...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  if (!problemEval) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <Text>No data available</Text>
      </Box>
    );
  }

  return (
    <Box
      maxW="90%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Button onClick={handleEditToggle}>
        {isEditing ? 'Cancel' : 'Edit'}
      </Button>

      {isEditing ? (
        <Button colorScheme="teal" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      ) : null}

      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
        <Box mb={5}>
          <Text fontSize="lg" fontWeight="bold">
            Problem Name:
          </Text>
          <Text>{problemEval.problem.question_name}</Text>
        </Box>

        <Box mb={5}>
          <Text fontSize="lg" fontWeight="bold">
            Problem Description:
          </Text>
          <MarkdownRenderer content={problemEval.problem.question_description} />
        </Box>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={6}>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Difficulty:
          </Text>
          <Tag>{formatStringEnums(problemEval.problem.difficulty)}</Tag>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Topic:
          </Text>
          <Tag>{formatStringEnums(problemEval.problem.topic)}</Tag>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Grade Level:
          </Text>
          <Tag>{formatStringEnums(problemEval.problem.grade_level)}</Tag>
        </Box>
      </Grid>

      <Box mt={5}>
        <Text fontSize="lg" fontWeight="bold">
          Correctly Solved:
        </Text>
        {isEditing ? (
          <Switch
            isChecked={problemEval.correctly_solved}
            onChange={(e) => setProblemEval({ ...problemEval, correctly_solved: e.target.checked })}
          />
        ) : (
          <Tag colorScheme={problemEval.correctly_solved ? 'green' : 'red'}>
            {problemEval.correctly_solved ? 'Yes' : 'No'}
          </Tag>
        )}
      </Box>

      <Box mt={5}>
        <Text fontSize="lg" fontWeight="bold">
          Correct Working:
        </Text>
        {isEditing ? (
          <Switch
            isChecked={problemEval.correct_working}
            onChange={(e) => setProblemEval({ ...problemEval, correct_working: e.target.checked })}
          />
        ) : (
          <Tag colorScheme={problemEval.correct_working ? 'green' : 'red'}>
            {problemEval.correct_working ? 'Yes' : 'No'}
          </Tag>
        )}
      </Box>

      {isEditing ? (
        <FormControl mt={5}>
          <FormLabel>Observation</FormLabel>
          <Input
            value={problemEval.observation}
            onChange={(e) => setProblemEval({ ...problemEval, observation: e.target.value })}
          />
        </FormControl>
      ) : (
        <Box mt={5}>
          <Text fontSize="lg" fontWeight="bold">
            Observation:
          </Text>
          <Text>{problemEval.observation}</Text>
        </Box>
      )}

      <Divider my={4} />

      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            LLM Working:
          </Text>
          <MarkdownRenderer content={problemEval.llm_working} />
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Actual Working:
          </Text>
          <MarkdownRenderer content={problemEval.problem.workings} />
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            LLM Answer:
          </Text>
          <MarkdownRenderer content={problemEval.llm_answer} />
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Actual Answer:
          </Text>
          <MarkdownRenderer content={problemEval.problem.final_answer} />
        </GridItem>
      </Grid>
    </Box>
  );
}
