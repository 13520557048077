import { Box, Text, useColorModeValue, Table, Thead, Tbody, Tr, Th, Td, Code } from '@chakra-ui/react'
import { InlineMath } from 'react-katex'

const latexCommands = [
  { latex: '\\frac{a}{b}', description: 'Fractions' },
  { latex: '\\sqrt{a}', description: 'Square roots' },
  { latex: '\\sqrt[n]{a}', description: 'nth roots' },
  { latex: '\\sum_{i=1}^{n} a_i', description: 'Summations' },
  { latex: '\\int_a^b f(x) \\, dx', description: 'Integrals' },
  { latex: '\\lim_{x \\to a} f(x)', description: 'Limit' },
  {
    latex:
      'a < b, \\quad a \\leq b, \\quad a > b, \\quad a \\geq b, \\quad a \\neq b',
    description: 'Inequalities',
  },
  { latex: 'a \\pm b', description: 'Plus or minus' },
  { latex: '\\infty', description: 'Infinity' },
  { latex: '\\angle ABC', description: 'Angles' },
  { latex: '45^{\\circ}', description: 'Degree' },
  {
    latex:
      '\\alpha, \\beta, \\gamma, \\delta, \\epsilon, \\theta, \\pi, \\sigma, \\phi, \\omega',
    description: 'Greek Letters',
  },
  {
    latex: '\\sin(x), \\cos(x), \\tan(x), \\csc(x), \\sec(x), \\cot(x)',
    description: 'Trigonometric Functions',
  },
  {
    latex: '\\sin^{-1}(x), \\cos^{-1}(x), \\tan^{-1}(x)',
    description: 'Inverse Trigonometric Functions',
  },
]

export default function LaTeXCheatsheet() {
  const color = useColorModeValue('gray.800', 'white')
  const backgroundColor = useColorModeValue('gray.100', 'gray.700')

  return (
    <Box
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      overflowX="auto"
      width="full"
    >
      <Text fontSize="xl" fontWeight="bold" mb={4} color={color}>
        LaTeX Cheatsheet
      </Text>
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th color={color}>Math Expression</Th>
            <Th color={color}>Latex Code</Th>
            <Th color={color}>Rendered Equation</Th>
          </Tr>
        </Thead>
        <Tbody>
          {latexCommands.map((item, index) => (
            <Tr key={index} bg={backgroundColor}>
              <Td color={color}>{item.description}</Td>
              <Td fontFamily="monospace" color={color}>{item.latex}</Td>
              <Td><InlineMath>{item.latex}</InlineMath></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box mt={10}>
        <Text fontSize="lg" fontWeight="bold" mb={4} color={color}>
          How to Enter Math Expressions:
        </Text>
        <Text fontSize="md" mb={2} color={color}>
          1. Use <Code>$$...$$</Code> delimiters for inline math. For example, <Code>$$a^2 + b^2 = c^2$$</Code> will render as inline math.
        </Text>
        <Text fontSize="md" color={color}>
          2. Use <Code>{"```KaTeX\n...\n```"}</Code> delimiters for block style math. For example, <Code>{"```KaTeX\na^2 + b^2 = c^2\n```"}</Code> will render as a block math equation.
        </Text>
      </Box>
    </Box>
  )
}
