import { useForm } from 'react-hook-form'
import {
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Box,
  Stack,
  Heading,
  Alert,
  AlertIcon,
  Badge,
  useDisclosure,
  Collapse,
  Checkbox,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import MarkdownEditor from '../components/MarkdownEditor'
import LaTeXCheatsheet from '../components/LatexCheatsheet'

// Constants
import { difficulties, topics, gradeLevels, serverUrl } from '../util/constants'

import authenticatedFetch from '../auth/auth-utility'

// Utils
import { formatStringEnums } from '../util/functions'
import { Problem } from '../util/interfaces'

const EditProblem = () => {
  const { uuid } = useParams()
  const { register, handleSubmit, formState, setValue } = useForm()
  const navigate = useNavigate()

  // for Latex cheatsheet
  const { isOpen, onToggle } = useDisclosure()

  const [problemData, setProblemData] = useState<Problem | null>(null)

  const [questionDescription, setQuestionDescription] = useState('')
  const [workings, setWorkings] = useState('')
  const [finalAnswer, setFinalAnswer] = useState('')

  // Track state of is_incomplete checkbox
  const [isIncomplete, setIsIncomplete] = useState<boolean>(false)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setIsIncomplete(isChecked)
    setValue('is_incomplete', isChecked)
  }

  useEffect(() => {
    const fetchProblemData = async (uuid: string) => {
      const response = await authenticatedFetch(`${serverUrl}/problems/${uuid}`)
      const data = await response.json()

      // Pre-fill form fields with fetched data
      Object.keys(data).forEach((key) => {
        console.log(`key: ${key}, value: ${data[key]}`)
        if (key === 'question_description') setQuestionDescription(data[key])
        else if (key === 'workings') setWorkings(data[key])
        else if (key === 'final_answer') setFinalAnswer(data[key])
        else setValue(key, data[key])
      })

      setIsIncomplete(data.is_incomplete)

      setProblemData(data)
    }
    // Fetch problem data when the component mounts
    if (uuid) {
      fetchProblemData(uuid)
    }
  }, [uuid, setValue])

  const onSubmit = async (values: any) => {
    // manually set Markdown Editor Values
    values['question_description'] = questionDescription
    values['workings'] = workings
    values['final_answer'] = finalAnswer

    // Define the server URL
    const serverUrl =
      process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'

    // Make PUT request to update problem
    let response

    try {
      response = await authenticatedFetch(`${serverUrl}/problems/${uuid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
    } catch (error: any) {
      throw error
    }

    // Check if request was successful
    if (response) {
      if (response.ok) {
        navigate(`/problems/${uuid}`)
      } else if (response.status === 401) {
        navigate('/')
      } else {
        console.error('Error: ', response.status, response.statusText)
      }
    }
  }

  return (
    <Box
      maxW="80%"
      p={5}
      mt={10}
      mx="auto"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading mb={5}>
        Edit Problem{' '}
        {problemData && (
          <Badge colorScheme={problemData.approved ? 'green' : 'red'}>
            {problemData.approved ? 'Approved' : 'Not Approved'}
          </Badge>
        )}
      </Heading>
      <Alert status="warning">
        <AlertIcon />
        Submitting this edit request will require a re-review and thus change
        the approval status from true to false.
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5}>
          <FormControl>
            <FormLabel htmlFor="name">Question Name</FormLabel>
            <Input
              placeholder="Enter a short name for the question"
              {...register('question_name')}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Difficulty</FormLabel>
            <Select placeholder="Select Difficulty" {...register('difficulty')}>
              {difficulties.map((difficulty) => {
                return (
                  <option key={difficulty} value={difficulty}>
                    {formatStringEnums(difficulty)}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Topic</FormLabel>
            <Select placeholder="Select Topic" {...register('topic')}>
              {topics.map((topic) => {
                return (
                  <option key={topic} value={topic}>
                    {formatStringEnums(topic)}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormControl>Grade Level</FormControl>
            <Select
              placeholder="Select Grade Level"
              {...register('grade_level')}
            >
              {gradeLevels.map((gradeLevel) => {
                return (
                  <option key={gradeLevel} value={gradeLevel}>
                    {formatStringEnums(gradeLevel)}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="is_incomplete">Is Incomplete?</FormLabel>
            <Checkbox
              {...register('is_incomplete')}
              isChecked={isIncomplete}
              onChange={handleCheckboxChange}
            >
              Yes
            </Checkbox>
          </FormControl>
          <Button onClick={onToggle} mt={4}>
            {isOpen ? 'Hide LaTeX Cheatsheet' : 'Show LaTeX Cheatsheet'}
          </Button>
          <Collapse in={isOpen}>
            <LaTeXCheatsheet />
          </Collapse>
          <FormControl>
            <FormLabel>Question Description</FormLabel>
            <MarkdownEditor
              value={questionDescription}
              setValue={setQuestionDescription}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Workings</FormLabel>
            <MarkdownEditor value={workings} setValue={setWorkings} />
          </FormControl>
          <FormControl>
            <FormLabel>Final Answer</FormLabel>
            <MarkdownEditor value={finalAnswer} setValue={setFinalAnswer} />
          </FormControl>
          <Button mt={4} isLoading={formState.isSubmitting} type="submit">
            Update Problem
          </Button>
        </Stack>
      </form>
    </Box>
  )
}

export default EditProblem
