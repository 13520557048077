// TODO: fetch these from the backend, maintain a single source of truth
export const topics = [
  'surds',
  'congruence_and_similarity_tests',
  'polynomials',
  'partial_fractions',
  'linear_law',
  'quadratic_functions',
  'equations_and_inequalities',
  'trigonometric_equations',
  'trigonometric_functions',
  'trigonometric_graphs',
  'trigonometric_identities',
  'applications_of_trigonometry',
  'arc_length_area_of_sector_and_radian_measure',
  'area_and_volume_of_similar_figures',
  'bisectors_and_scale_drawings',
  'coordinate_geometry',
  'basic_trigonometry',
  'further_trigonometry',
  'geometric_properties_of_circles',
  'graphs_of_functions',
  'graphs_of_real_world_content',
  'indices_and_standard_form',
  'quadratic_and_fractional_equations',
  'simple_and_compound_interest',
  'simultaneous_equations',
  'quadratic_inequalities',
  'indices',
  'logarithms',
  'binomial_theorem', 
  'equation_of_a_circle',
  'four_operations_of_decimals',
  'factors_and_multiples',
  'decimals',
  'area and perimeter',
  'differentiation and its techniques',
  'differentiation on tangents and normals',
  'differentiation on rates of change',
  'higher order differentiation',
  'integration',
  'integration of area',
  'kinematics',
  'plane geometry',
  'vectors', 
  'matrices',
  'probability',
  'sets',
  'simultaneous_linear_equations',
  'ratio_rate_and_speed',
  'percentage',
  'number_patterns',
  'linear_equations',
  'basic_algebra_and_algebraic_manipulation',
  'approximation_and_estimation',
  'integers_rational_numbers_and_real_numbers',
  'primes_highest_common_factors_and_lowest_common_multiples',
  "linear_graphs_and_simultaneous_linear_equations",
  "linear_inequalities",
  "expansion_and_factorisation_of_quadratic_expressions",
  "expansion_and_factorisation_using_special_algebraic_identities",
  "quadratic_equations_and_graphs",
  "algebraic_fractions_and_formulae",
  "direct_and_inverse_properties",
  "congruence_and_similarity"
]

export const added_bys = ['shared@mindstretcher.com']

export const difficulties = ['basic', 'intermediate', 'advanced', 'real_world_context']

export const gradeLevels = ['secondary_3_add_math', 'secondary_4', 'primary_4', 'secondary_3_e_math', 'secondary_4_add_math', 'secondary_4_e_math', 'secondary_2_math', 'secondary_1_math']

export const serverUrl =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'
